window.ImageSlider = (imageSliderId) => {
	return {
		state: {
			moving: true,
		},
		get pictures () {return this.$el.querySelector(`.acf-innerblocks-container`)},
		position: 0,
		speed: 25,
		init() {
            // loop is not infinite, it's just a trick to make the slider loop
            const clonedPics = this.pictures.cloneNode(true);
            const clonedPics2 = this.pictures.cloneNode(true);
            const clonedPics3 = this.pictures.cloneNode(true);
            const clonedPics4 = this.pictures.cloneNode(true);
            const clonedPics5 = this.pictures.cloneNode(true);
            this.$el.querySelector(`#${imageSliderId}`).appendChild(clonedPics);
            this.$el.querySelector(`#${imageSliderId}`).appendChild(clonedPics2);
            this.$el.querySelector(`#${imageSliderId}`).appendChild(clonedPics3);
            this.$el.querySelector(`#${imageSliderId}`).appendChild(clonedPics4);
            this.$el.querySelector(`#${imageSliderId}`).appendChild(clonedPics5);

			this.$nextTick(() => {
				this.start();
			});
		},
		start() {
			this.loop = () => {
				this.position = this.position - this.speed;
				setTimeout(() => {
					requestAnimationFrame(this.loop);
				}, 300); // Equal to Duration of transition
			};
			requestAnimationFrame(this.loop);
		},
	};
};
