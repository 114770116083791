window.AcfImageSlider = (allImages) => {
    return {
		state: {
			moving: true,
		},
		pictures: allImages,
		position: 0,
		speed: 25,
		offset: 0,
		pauseOnNextPicture: 0,
		init() {
			this.loop = () => {
				const first = document.getElementById('picture-0').getBoundingClientRect()
				if (first.x <= -first.width) {
					// Move the first picture to the end of the array
					const newPictures = this.pictures
					newPictures.push(newPictures.shift())
					this.pictures = newPictures
					this.offset = this.offset + Math.abs(first.width)
					this.$nextTick(() => {
						setTimeout(() => {
							requestAnimationFrame(this.loop)
						}, this.pauseOnNextPicture)
					})
					return
				}
				this.position = this.state.moving ? this.position - this.speed : this.position
				setTimeout(() => {
					requestAnimationFrame(this.loop)
				}, 300) // Matches duration-300 tailwind class on ul
			}
			requestAnimationFrame(this.loop)
		},
	};
}