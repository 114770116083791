window.InteractiveMap = (mapPoints) => {
	return {
		mapPoints: mapPoints,
		currentCityName: mapPoints[0].city,
		currentCityDriveTime: mapPoints[0].driveTime,
		cityIndex: 0,
		intervalId: null,

		init() {
			this.start();
		},

		start() {
			this.intervalId = setInterval(() => {
				if (this.cityIndex < this.mapPoints.length - 1) {
					this.cityIndex++;
				} else {
					this.cityIndex = 0;
				}
				this.currentCityName = this.mapPoints[this.cityIndex].city;
				this.currentCityDriveTime = this.mapPoints[this.cityIndex].driveTime;

			}, 2500);
		},
		pause() {
			// stop the interval so that the map doesn't update
			clearInterval(this.intervalId);
			// update the current city details based on the hovered index
			this.currentCityName = this.mapPoints[this.cityIndex].city;
			this.currentCityDriveTime = this.mapPoints[this.cityIndex].driveTime;
		},
		resume() {
			// start the interval again
			this.start();

		},
		updateOnHover(index) {
			this.cityIndex = index;
			this.currentCityName = this.mapPoints[this.cityIndex].city;
			this.currentCityDriveTime = this.mapPoints[this.cityIndex].driveTime;
		}
	};
};
