window.StatBlock = targetNumber => {
    return {
        startAnimation: () => {
            let startTimestamp = null;

            const step = (timestamp) => {
                if (!startTimestamp) startTimestamp = timestamp;
                const elapsed = timestamp - startTimestamp;
                const progress = Math.min(elapsed / 5000, 1);

                const num = Math.floor(progress * targetNumber);
                document.getElementById('statNumber').textContent = num.toLocaleString('en-US');

                if (progress < 1) {
                    window.requestAnimationFrame(step);
                } else {
                    document.getElementById('statNumber').textContent = targetNumber.toLocaleString('en-US');
                }
            };

            window.requestAnimationFrame(step);
        },
    };
};
